<template>
  <b-modal
    v-model="state"
    content-class="rounded-0"
    :ok-title="$t(actionBtnLabel)"
    ok-variant="secondary"
    ok-only
    @cancel="closeModal"
    @ok="onApprove"
  >
    <template v-slot:modal-title>
      <span v-html="$t(titleText, titleParams)"></span>
    </template>
    <p v-html="$t(messageText, messageParams)"></p>
  </b-modal>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$store.dispatch("error/RESET");
    },
    onApprove() {
      this.$store.dispatch("error/close");
    }
  },
  computed: {
    state: {
      get() {
        return this.$store.getters["error/isActive"];
      },
      set(active) {
        this.$store.commit("error/SET_ACTIVE", active);
      }
    },
    titleText() {
      return this.$store.getters["error/getTitleText"];
    },
    titleParams() {
      return this.$store.getters["error/getTitleParams"];
    },
    messageText() {
      return this.$store.getters["error/getMessageText"];
    },
    messageParams() {
      return this.$store.getters["error/getMessageParams"];
    },
    actionBtnLabel() {
      return this.$store.getters["error/getActionBtnLabel"];
    }
  }
};
</script>

<style scoped></style>
