/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
const strict = true;

const getDefaultState = () => {
  return {
    tourActive: false
  };
};

const state = getDefaultState();

const getters = {
  isTourActive: state => state.tourActive
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_TOUR_ACTIVE(state, tourActive) {
    state.tourActive = tourActive;
  }
};

const actions = {};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
