import Utils from "../Utils";
import uniqueId from "lodash/uniqueId";
const strict = true;

const getDefaultState = () => {
  return {
    questionTypeOptions: [
      {
        text: "singleChoice",
        value: Utils.QUESTION_TYPE.SINGLE_CHOICE
      },
      {
        text: "multiChoice",
        value: Utils.QUESTION_TYPE.MULTI_CHOICE
      },
      // {
      //   text: "openEnded",
      //   value: Utils.QUESTION_TYPE.OPEN_ENDED
      // },
      {
        text: "scale",
        value: Utils.QUESTION_TYPE.SCALE
      }
    ],
    questionList: []
  };
};

const state = getDefaultState();

const getters = {
  getQuestionTypeOptions: state => state.questionTypeOptions,
  getQuestionList: state => state.questionList
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  INIT_QUESTION_LIST(state, questionList) {
    state.questionList = []; // reset list
    let questionItem = {};
    questionList.forEach(question => {
      questionItem = {};
      questionItem.id = uniqueId("question_");
      questionItem.type = question.type;
      questionItem.text = question.text;
      // if question.type SINGLE_CHOICE or MULTI_CHOICE
      if (
        question.answers &&
        question.answers.answerList &&
        question.answers.answerList.length > 0
      ) {
        questionItem.answerList = [];
        question.answers.answerList.forEach(answer => {
          questionItem.answerList.push({
            id: uniqueId("answer_"),
            text: answer
          });
        });
        questionItem.randomAnswerOrder = question.answers.randomOrder;
      } else {
        questionItem.answerList = [];
        questionItem.randomAnswerOrder = false;
      }
      state.questionList.push(questionItem);
    });
  },
  SET_QUESTION_LIST(state, questionList) {
    state.questionList = questionList;
  },
  ADD_QUESTION(state, question) {
    state.questionList.push(question);
  },
  UPDATE_QUESTION(state, question) {
    const index = state.questionList.findIndex(q => q.id === question.id);
    if (index > -1) state.questionList[index] = question;
  },
  REMOVE_QUESTION(state, questionId) {
    state.questionList = state.questionList.filter(q => {
      return q.id !== questionId;
    });
  }
};

const actions = {
  // updateQuestion({getters}, question) {}
};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
