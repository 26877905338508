<template>
  <b-modal
    v-model="state"
    size="lg"
    content-class="rounded-0"
    footer-class="border-top-0"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @ok="onApprove"
  >
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase">
        <span v-html="$t(titleText, titleParams)"></span>
      </h5>
    </template>
    <template v-slot:default>
      <p v-html="$t(messageText, messageParams)"></p>
    </template>
    <template v-slot:modal-footer="{ ok }">
      <b-button variant="primary" @click="ok">
        {{ $t(confirmBtnLabel) }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  methods: {
    onCancel() {
      this.$store.dispatch("success/cancelAction");
    },
    onApprove() {
      this.$store.dispatch("success/confirmAction");
    }
  },
  computed: {
    state: {
      get() {
        return this.$store.getters["success/isActive"];
      },
      set(active) {
        this.$store.commit("success/SET_ACTIVE", active);
      }
    },
    titleText() {
      return this.$store.getters["success/getTitleText"];
    },
    titleParams() {
      return this.$store.getters["success/getTitleParams"];
    },
    messageText() {
      return this.$store.getters["success/getMessageText"];
    },
    messageParams() {
      return this.$store.getters["success/getMessageParams"];
    },
    confirmBtnLabel() {
      return this.$store.getters["success/getConfirmBtnLabel"];
    }
  }
};
</script>

<style scoped></style>
