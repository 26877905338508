import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./plugins/bootstrap-vue";
import "./plugins/vee-validation";
import "./plugins/yandex-metrica";

import VueGtag from "vue-gtag";
import VueTour from "vue-tour";

import dayjs from "dayjs";
import "dayjs/locale/it";
import "dayjs/locale/en";

import i18n from "./i18n";

import Draggable from "vuedraggable";
import BaseLoaderDialog from "./components/Base/BaseLoaderDialog";
import BaseErrorDialog from "./components/Base/BaseErrorDialog";
import BaseConfirmDialog from "./components/Base/BaseConfirmDialog";
import BaseSuccessDialog from "./components/Base/BaseSuccessDialog";
import Config from "../src/plugins/Config";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

Vue.use(
  VueGtag,
  {
    config: {
      id: Config.value("clientsGoogleAnalyticsId")
      //   send_page_view: false
      // }
    }
  },
  router
);

Vue.component("base-loader-dialog", BaseLoaderDialog);
Vue.component("base-error-dialog", BaseErrorDialog);
Vue.component("base-confirm-dialog", BaseConfirmDialog);
Vue.component("base-success-dialog", BaseSuccessDialog);
Vue.component("draggable", Draggable);

Vue.config.productionTip = false;

Vue.filter("fileSize", size => {
  const sizeMB = size / 1024 / 1024 || 0.0;
  return Number.parseFloat(sizeMB).toFixed(2);
});

Vue.filter("fileName", name => {
  if (!name) return "NOT AVAILABLE";
  const filenameLengh = name.length;
  if (filenameLengh > 20) {
    const ext = /[.]/.exec(name) ? name.split(".").pop() : undefined;
    const shortName = name.slice(0, 10) + "...";
    return ext && ext.length > 0
      ? shortName +
          name.slice(
            filenameLengh - ext.length - 4,
            filenameLengh - ext.length
          ) +
          ext
      : shortName + name.slice(filenameLengh - 4, filenameLengh);
  } else return name;
});

Vue.filter("price", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("it-IT", {
    currency: "EUR",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter("date", (date, format) => {
  const dayjsDate = dayjs(String(date));
  return dayjsDate.isValid()
    ? dayjsDate.locale(i18n.locale).format(format)
    : i18n.t("notAvailable");
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
